<template>
  <div>
    <v-snackbar v-model="noNearbyAddressSnackbar">
      {{ $t("tripUserEdit.noNearbyAddressAround") }}
    </v-snackbar>
    <v-snackbar v-model="outsideCoverageAreaSnackbar">
      {{ $t("tripUserEdit.outsideCoverageArea") }}
    </v-snackbar>
    <v-bottom-sheet v-model="sheet" persistant @click:outside="close">
      <v-sheet>
        <v-container>
          <v-card-text>
            <location-dialog
              v-model="location"
              :label="$t('tripUserEdit.locationInput.label')"
              :language="deviceStore.language"
            />
            <travel-mode-toggle
              v-model="travelMode"
              :label="$t('tripUserEdit.modeSelectLabel')"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-show="showUpdateBtn"
              class="rounded-lg"
              color="primary"
              large
              @click="update"
            >
              {{ $t("tripUserEdit.action") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { BusinessException } from "@/services/middler/errors";
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import GISService from "@/services/middler/GISService";
import LocationDialog from "@/components/dialogs/LocationDialog.vue";
import TravelModeToggle from "@/components/inputs/TravelModeToggle.vue";
import VueRouter from "vue-router";

export default {
  components: {
    LocationDialog,
    TravelModeToggle,
  },
  data() {
    const tripId = this.$route.params.tripId;
    const userId = this.$route.params.userId;
    const tripUser = this.$tripUserRepo
      .where("tripId", tripId)
      .where("userId", userId)
      .with("user")
      .first();
    const location = {
      lat: tripUser.lat,
      lng: tripUser.lng,
      address: tripUser.address,
    };
    return {
      gisService: new GISService(),
      location,
      noNearbyAddressSnackbar: false,
      outsideCoverageAreaSnackbar: false,
      sheet: false,
      travelMode: tripUser.travelMode,
      tripId,
      tripUser,
      userId,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
    showUpdateBtn() {
      return (
        this.location.address !== this.tripUser.address ||
        this.travelMode !== this.tripUser.travelMode
      );
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      async handler(query) {
        if (!query.lat || !query.lng) {
          this.sheet = true;
          return;
        }
        const point = `POINT(${query.lng} ${query.lat})`;
        try {
          const data = await this.gisService.reverseGeocode(
            point,
            this.deviceStore.language
          );
          if (!data.features.length) {
            this.noNearbyAddressSnackbar = true;
            setTimeout(() => {
              this.noNearbyAddressSnackbar = false;
              this.close();
            }, 1000);
            return;
          }
          this.sheet = true;
          const feature = data.features[0];
          this.location = {
            lat: feature.geometry.coordinates[1],
            lng: feature.geometry.coordinates[0],
            address: feature.properties.address,
          };
        } catch (error) {
          if (
            error instanceof BusinessException &&
            error.code === "outside_coverage_area"
          ) {
            this.outsideCoverageAreaSnackbar = true;
            setTimeout(() => {
              this.outsideCoverageAreaSnackbar = false;
              this.close();
            }, 1000);
          } else {
            throw error;
          }
        }
      },
    },
    location: {
      deep: true,
      handler(location) {
        const { isNavigationFailure, NavigationFailureType } = VueRouter;
        this.$router
          .push({
            query: {
              lat: location.lat,
              lng: location.lng,
              address: location.address,
            },
          })
          .catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw Error(error);
            }
          });
      },
    },
  },
  methods: {
    close() {
      this.$router.push({
        name: "tripMap",
        params: { tripId: this.tripId },
      });
    },
    async update() {
      this.tripUser.address = this.location.address;
      this.tripUser.lat = this.location.lat;
      this.tripUser.lng = this.location.lng;
      this.tripUser.travelMode = this.travelMode;
      await this.$tripUserRepo.update(this.tripUser);
      this.close();
    },
  },
};
</script>
