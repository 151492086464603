<template>
  <v-btn v-bind="$attrs" icon :to="to">
    <v-icon>mdi-arrow-left</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default: undefined,
    },
    query: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    to() {
      return {
        name: this.$router.currentRoute.meta.backRouteName,
        params: this.params,
        query: this.query,
      };
    },
  },
};
</script>
