var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('v-app-bar',{attrs:{"app":"","color":"transparent","dark":"","flat":""}},[_c('v-btn',{staticClass:"mt-1",attrs:{"outlined":"","small":"","to":{
        name: 'userLogin',
        query: { redirect: _vm.$route.query.redirect },
      }}},[_vm._v(_vm._s(_vm.$t("userAuth.login")))]),_c('v-spacer'),_c('language-select',{model:{value:(_vm.deviceStore.language),callback:function ($$v) {_vm.$set(_vm.deviceStore, "language", $$v)},expression:"deviceStore.language"}})],1),_c('v-main',{staticClass:"fill-height"},[_c('v-container',{staticClass:"fill-height white--text"},[_c('div',{staticClass:"logo-container"},[_c('div',{staticClass:"text-h2 text-lg-h1"},[_vm._v("Middler")]),_c('div',{staticClass:"text-h7 text-lg-h6"},[_vm._v("Paris - Île-de-France")])]),_c('div',{staticClass:"scroller text-h6 text-lg-h5"},[_c('div',{staticClass:"flex flex-column"},[_c('v-icon',{staticClass:"mb-3",attrs:{"dark":"","large":""}},[_vm._v("mdi-subway-variant")]),_c('span',[_vm._v(_vm._s(_vm.$t("userAuth.find")))]),_c('v-btn',{staticClass:"mt-12",attrs:{"color":"white","dark":"","fab":"","outlined":"","small":""},on:{"click":_vm.scrollToNext}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)],1),_c('div',{staticClass:"flex flex-column"},[_c('v-icon',{staticClass:"mb-3",attrs:{"dark":"","large":""}},[_vm._v("mdi-silverware-fork-knife")]),_c('span',[_vm._v(_vm._s(_vm.$t("userAuth.discover")))]),_c('v-btn',{staticClass:"mt-12",attrs:{"color":"white","dark":"","fab":"","outlined":"","small":""},on:{"click":_vm.scrollToNext}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)],1),_c('div',{staticClass:"flex flex-column"},[_c('v-icon',{staticClass:"mb-3",attrs:{"dark":"","large":""}},[_vm._v("mdi-thumb-up")]),_c('span',[_vm._v(_vm._s(_vm.$t("userAuth.simplify")))]),_c('v-btn',{staticClass:"mt-12",attrs:{"color":"white","dark":"","fab":"","outlined":"","small":""},on:{"click":_vm.scrollToNext}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)],1),_c('div',[_c('v-btn',{staticClass:"my-3",attrs:{"dark":"","outlined":"","rounded":"","to":{
              name: 'userSignUpStep1',
              query: { redirect: _vm.$route.query.redirect },
            },"x-large":""}},[_vm._v(" "+_vm._s(_vm.$t("userAuth.signUp"))+" ")])],1)])])],1),_c('v-footer',{staticClass:"white--text pb-1",attrs:{"color":"transparent","fixed":""}},[_c('v-spacer'),_vm._v(_vm._s(_vm.appVersion)+" ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }