<template>
  <div class="background">
    <v-app-bar app color="transparent" dark flat>
      <v-btn
        class="mt-1"
        outlined
        small
        :to="{
          name: 'userLogin',
          query: { redirect: $route.query.redirect },
        }"
        >{{ $t("userAuth.login") }}</v-btn
      >
      <v-spacer />
      <language-select v-model="deviceStore.language" />
    </v-app-bar>
    <v-main class="fill-height">
      <v-container class="fill-height white--text">
        <div class="logo-container">
          <div class="text-h2 text-lg-h1">Middler</div>
          <div class="text-h7 text-lg-h6">Paris - Île-de-France</div>
        </div>
        <div class="scroller text-h6 text-lg-h5">
          <div class="flex flex-column">
            <v-icon class="mb-3" dark large>mdi-subway-variant</v-icon>
            <span>{{ $t("userAuth.find") }}</span>
            <v-btn
              class="mt-12"
              color="white"
              dark
              fab
              outlined
              small
              @click="scrollToNext"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </div>
          <div class="flex flex-column">
            <v-icon class="mb-3" dark large>mdi-silverware-fork-knife</v-icon>
            <span>{{ $t("userAuth.discover") }}</span>
            <v-btn
              class="mt-12"
              color="white"
              dark
              fab
              outlined
              small
              @click="scrollToNext"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </div>
          <div class="flex flex-column">
            <v-icon class="mb-3" dark large>mdi-thumb-up</v-icon>
            <span>{{ $t("userAuth.simplify") }}</span>
            <v-btn
              class="mt-12"
              color="white"
              dark
              fab
              outlined
              small
              @click="scrollToNext"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </div>
          <div>
            <v-btn
              class="my-3"
              dark
              outlined
              rounded
              :to="{
                name: 'userSignUpStep1',
                query: { redirect: $route.query.redirect },
              }"
              x-large
            >
              {{ $t("userAuth.signUp") }}
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-main>
    <v-footer class="white--text pb-1" color="transparent" fixed>
      <v-spacer />{{ appVersion }}
    </v-footer>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import LanguageSelect from "@/components/LanguageSelect.vue";

export default {
  components: {
    LanguageSelect,
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
  },
  methods: {
    scrollToNext() {
      this.$el.querySelector(".scroller").scrollBy({
        top: this.$el.querySelector(".scroller").clientHeight,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.background
  background: rgb(0,176,255)
  background: linear-gradient(170deg, rgba(0,176,255,1) 20%, rgba(227,165,91,1) 100%)
  height: 100%

.logo-container
  padding: 10vh 0
  text-align: center
  width: 100%

.scroller
  height: 40vh
  overflow-y: scroll
  scroll-snap-type: y mandatory
  scrollbar-width: none
  width: 100%

  div
    align-items: center
    display: flex
    height: 40vh
    justify-content: center
    scroll-snap-align: start
    text-align: center
    width: 100%
</style>
