<template>
  <v-item-group class="my-3" :value="value" @change="onChange($event)">
    <v-item v-slot="{ active, toggle }" value="restaurants">
      <v-btn
        active-class="primary--text"
        class="mb-2 mr-2"
        :input-value="active"
        rounded
        @click="toggle"
      >
        <v-icon left>mdi-silverware-fork-knife</v-icon>
        {{ $t("placeTypeToggle.restaurants") }}
      </v-btn>
    </v-item>
    <v-item v-slot="{ active, toggle }" value="bars_and_pubs">
      <v-btn
        active-class="primary--text"
        class="mb-2 mr-2"
        :input-value="active"
        rounded
        @click="toggle"
      >
        <v-icon left>mdi-glass-cocktail</v-icon>
        {{ $t("placeTypeToggle.barsAndPubs") }}
      </v-btn>
    </v-item>
    <v-item v-slot="{ active, toggle }" value="coffee_and_tea">
      <v-btn
        active-class="primary--text"
        class="mb-2 mr-2"
        :input-value="active"
        rounded
        @click="toggle"
      >
        <v-icon left>mdi-coffee</v-icon>
        {{ $t("placeTypeToggle.coffeeAndTea") }}
      </v-btn>
    </v-item>
  </v-item-group>
</template>

<script>
import { VInput } from "vuetify/lib";

export default {
  extends: VInput,
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
  },
};
</script>
