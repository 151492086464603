<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    :width="width"
  >
    <template #activator="{ on, attrs }">
      <v-textarea
        ref="locationInput"
        v-bind="attrs"
        auto-grow
        :label="label"
        readonly
        rows="1"
        :value="value ? value.address : ''"
        v-on="on"
        @click="search = ''"
      />
    </template>
    <v-card>
      <v-toolbar v-if="$vuetify.breakpoint.mobile" color="white" flat>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-textarea
          v-model="search"
          auto-grow
          autofocus
          :placeholder="$t('locationDialog.searchField.placeholder')"
          rows="1"
        />
        <v-btn
          v-if="canGetCurrentPosition"
          block
          color="primary"
          :loading="getCurrentPositionLoading"
          outlined
          @click="getGeolocation"
        >
          <v-icon left>mdi-target</v-icon>
          Use current location
        </v-btn>
        <v-list v-if="results.length === 0">
          <div
            v-for="(departureAddress, index) in $departureAddressRepo.all()"
            :key="departureAddress.id"
          >
            <v-list-item
              two-line
              @click="onDepartureAddressClick(departureAddress)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ departureAddress.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ departureAddress.address }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click.stop="destroyDepartureAddress(departureAddress)"
                >
                  <v-icon>mdi-star</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < $departureAddressRepo.all().length - 1" />
          </div>
        </v-list>
        <v-list>
          <div v-for="(result, index) in results" :key="`result-${index}`">
            <v-list-item two-line @click="onResultClick(result)">
              <v-list-item-content>
                {{ result.address }}
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-if="isDepartureAddress(result)"
                  icon
                  @click.stop="destroyDepartureAddress(result)"
                >
                  <v-icon>mdi-star</v-icon>
                </v-btn>
                <v-btn v-else icon @click.stop="newDepartureAddress = result">
                  <v-icon>mdi-star-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < results.length - 1" />
          </div>
        </v-list>
      </v-container>
    </v-card>
    <departure-address-dialog
      v-if="newDepartureAddress"
      :location="newDepartureAddress"
      @close="
        newDepartureAddress = null;
        search = '';
      "
    />
  </v-dialog>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { VInput } from "vuetify/lib";
import DepartureAddressDialog from "@/components/dialogs/DepartureAddressDialog.vue";
import GISService from "@/services/middler/GISService";

export default {
  components: {
    DepartureAddressDialog,
  },
  extends: VInput,
  props: {
    language: {
      type: String,
      required: true,
    },
  },
  data() {
    const canGetCurrentPosition =
      Capacitor.isNativePlatform() || window.isSecureContext;

    return {
      canGetCurrentPosition,
      dialog: false,
      getCurrentPositionLoading: false,
      gisService: new GISService(),
      newDepartureAddress: null,
      results: [],
      search: "",
    };
  },
  computed: {
    width() {
      const { name, thresholds } = this.$vuetify.breakpoint;
      return (thresholds[name] || 1920) * 0.5;
    },
  },
  watch: {
    search: {
      async handler(value) {
        if (!value) {
          this.results = [];
          return;
        }
        const proximity = "POINT(2.333333 48.866667)"; // Paris
        try {
          const data = await this.gisService.geocode(
            value,
            this.language,
            proximity
          );
          this.results = data.features.map((feature) => ({
            address: feature.properties.address,
            lat: feature.geometry.coordinates[1],
            lng: feature.geometry.coordinates[0],
          }));
        } catch (error) {
          console.error(error); // TODO: handle errors nicely
        }
      },
    },
  },
  created() {
    this.$departureAddressRepo.list();
  },
  methods: {
    async getCurrentPosition() {
      this.getCurrentPositionLoading = true;
      if (Capacitor.isNativePlatform()) {
        return await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        }).finally(() => {
          this.getCurrentPositionLoading = false;
        });
      } else if (navigator.geolocation) {
        return await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }).finally(() => {
          this.getCurrentPositionLoading = false;
        });
      }
    },
    async getGeolocation() {
      const position = await this.getCurrentPosition();
      const point = `POINT(${position.coords.longitude} ${position.coords.latitude})`;
      this.gisService
        .reverseGeocode(point, this.language)
        .then((data) => {
          const feature = data.features[0];
          const location = {
            lat: feature.geometry.coordinates[1],
            lng: feature.geometry.coordinates[0],
          };
          location.address = feature.properties.address;
          this.$emit("input", location);
        })
        .catch((error) => {
          console.error(error); // TODO: handle errors nicely
        })
        .finally(() => {
          this.dialog = false;
        });
    },
    createDepartureAddress(result) {
      this.$departureAddressRepo.create({
        name: "Work",
        address: result.address,
        lat: result.lat,
        lng: result.lng,
      });
    },
    destroyDepartureAddress(result) {
      const departureAddress = this.$departureAddressRepo
        .where("address", result.address)
        .first();
      this.$departureAddressRepo.remove(departureAddress);
    },
    isDepartureAddress(result) {
      return (
        this.$departureAddressRepo.where("address", result.address).first() !==
        null
      );
    },
    onDepartureAddressClick(departureAddress) {
      this.$emit("input", departureAddress);
      this.dialog = false;
    },
    onResultClick(result) {
      this.$emit("input", result);
      this.dialog = false;
    },
  },
};
</script>
