import { Model } from "pinia-orm";
import Place from "@/models/Place";

export default class TripadvisorImage extends Model {
  static entity = "tripadvisorImages";
  static fields() {
    return {
      id: this.uid(),
      placeId: this.string(null),
      place: this.belongsTo(Place, "placeId"),
      favorite: this.boolean(false),
      filepath: this.string(null),
    };
  }

  get url() {
    return `https://media-cdn.tripadvisor.com/media/photo-s${this.filepath}`;
  }
}
