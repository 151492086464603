<template>
  <v-app>
    <v-snackbar v-model="networkError" :timeout="2000" top>
      {{ $t("app.networkError") }}
    </v-snackbar>
    <updater-dialog />
    <router-view />
  </v-app>
</template>

<script>
import { App as CapacitorApp } from "@capacitor/app";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Network } from "@capacitor/network";
import { NetworkError } from "@/services/middler/errors.js";
import { SplashScreen } from "@capacitor/splash-screen";
import { localize } from "vee-validate";
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import { useNetworkStore } from "@/stores/network";
import UpdaterDialog from "@/components/dialogs/UpdaterDialog";
import Vue from "vue";
import camelcaseKeysDeep from "camelcase-keys-deep";
import client from "@/services/middler/client.js";
import websocket from "@/websocket";

export default {
  name: "App",
  components: {
    UpdaterDialog,
  },
  data() {
    return {
      networkError: false,
    };
  },
  computed: {
    ...mapStores(useDeviceStore, useNetworkStore),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  created() {
    websocket.addEventListener("message", this.onWebsocketMessage);
  },
  beforeDestroy() {
    CapacitorApp.removeAllListeners();
    FirebaseMessaging.removeAllListeners();
    Network.removeAllListeners();

    websocket.removeEventListener("message", this.onWebsocketMessage);
    websocket.close();
  },
  async mounted() {
    // Handle network errors
    Vue.config.errorHandler = (error) => {
      if (error instanceof NetworkError) {
        this.networkError = true;
      } else {
        throw error;
      }
    };

    // Subscribe to device store changes
    this.deviceStore.$subscribe((mutation, state) => {
      this.$vuetify.theme.dark = state.darkMode;
      this.$i18n.locale = state.language;
      client.defaults.headers["Accept-Language"] = state.language;
      localize(state.language);
    });

    // Handle push notifications
    FirebaseMessaging.addListener(
      "notificationReceived",
      this.onNotificationReceived
    );

    // Handle hardware back button
    CapacitorApp.addListener("backButton", () => {
      const currentRoute = this.$router.currentRoute;
      if (
        currentRoute.name === "tripList" ||
        currentRoute.name === "userAuth"
      ) {
        // Exit the app if the user is on the trip list page or the auth page
        navigator.app.exitApp();
      } else {
        const backRouteName = currentRoute.meta.backRouteName;
        if (backRouteName) {
          this.$router.push({ name: backRouteName });
        }
      }
    });

    // Get network status
    const status = await Network.getStatus();
    this.networkStore.$patch(status);

    // Handle network status changes
    Network.addListener("networkStatusChange", (status) => {
      this.networkStore.$patch(status);
    });

    // Hide the splash screen
    await SplashScreen.hide();
  },
  methods: {
    onNotificationReceived({ notification: payload }) {
      // Show a notification only if the user is not on the trip detail page
      const tripId = payload.data.trip_id;
      if (this.$route.params.tripId !== tripId) {
        const notification = new Notification(payload.title, {
          body: payload.body,
        });
        notification.onclick = () => {
          this.$router.push({ path: payload.data.path });
        };
      }
    },
    onWebsocketMessage(event) {
      const data = JSON.parse(event["data"]);
      const item = camelcaseKeysDeep(data.data);
      if (data.resource === "trip" && data.action === "update") {
        const tripId = data.id;
        this.$tripRepo.retrieve(tripId);
        this.$userRepo.listByTrip(tripId);
        const trip = this.$tripRepo.find(tripId);
        if (trip) {
          this.$placeRepo.list(
            tripId,
            trip.cuisines,
            trip.priceRanges,
            trip.insideParis
          );
          this.$placeRepo.listFromPolls(tripId);
        }
      }
      if (data.resource === "trip" && data.action === "delete") {
        const tripId = data.id;
        this.$tripRepo.destroy(tripId);
        if (this.$route.params.tripId === tripId) {
          this.$router.push({ name: "tripList" });
        }
      }
      if (data.resource === "message" && data.action === "create") {
        this.$messageRepo.save(item);
        const tripId = item.tripId;
        this.$messageRepo.markAsReceived(tripId).then(() => {
          // Still retrieves all messages for caching purpose
          this.$messageRepo.list(tripId);
        });
      }
      if (
        data.resource === "poll" &&
        ["create", "update"].includes(data.action)
      ) {
        this.$pollRepo.save(item);
        // Still retrieves all polls for caching purpose
        const tripId = item.tripId;
        this.$pollRepo.list(tripId);
      }
    },
  },
};
</script>

<style lang="sass">
a
  -webkit-touch-callout: none

.v-application
  padding-top: env(safe-area-inset-top)

.v-toolbar
  padding-top: env(safe-area-inset-top)
  height: calc(env(safe-area-inset-top) + 64px) !important

.v-footer
  padding-bottom: env(safe-area-inset-bottom) !important
</style>
