import { Repository } from "pinia-orm";
import { useLoadingStore } from "@/stores/loading";
import Message from "@/models/Message";
import MessageService from "@/services/middler/MessageService";

export default class MessageRepository extends Repository {
  use = Message;
  service = new MessageService();

  async list(tripId) {
    const loadingStore = useLoadingStore();
    loadingStore["message.list"] = true;
    const results = await this.service.list(tripId);
    this.save(results);
    loadingStore["message.list"] = false;
  }

  async create(tripId, text) {
    const result = await this.service.create(tripId, {
      text,
    });
    this.save(result);
  }

  async markAsReceived(tripId) {
    const results = await this.service.markReceived(tripId);
    this.save(results);
  }

  async markAsRead(tripId) {
    const results = await this.service.markRead(tripId);
    this.save(results);
  }
}
