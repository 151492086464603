<template>
  <v-card class="d-flex rounded-xl" height="20vh" @click="onCardClick">
    <img
      v-if="imageSrc"
      class="rounded-l-xl rounded-r-0"
      referrerpolicy="no-referrer"
      :src="imageSrc"
      style="width: 45%; object-fit: cover"
    />
    <div class="index">
      <span>{{ index + 1 }}</span>
    </div>
    <div class="px-3 py-1" style="width: 55%">
      <div class="text-h6">{{ place.name }}</div>
      <div v-if="!$vuetify.breakpoint.mobile">{{ place.address }}</div>
      <div>{{ cuisines }}</div>
      <div v-if="place.priceRange">{{ priceRange }}</div>
      <v-icon small>
        {{ travelModes[authTripUser.travelMode].icon }}
      </v-icon>
      {{ strfSeconds(durationByPlaceId[place.id]) }}
    </div>
    <div class="actions">
      <see-on-map-btn
        v-if="showMapBtn"
        color="primary"
        fab
        :place-id="place.id"
        rounded
        :trip-id="tripId"
        x-small
      />
      <vote-btn color="primary" fab :place-id="place.id" :trip-id="tripId" />
    </div>
  </v-card>
</template>

<script>
import { getTravelModes } from "@/constants/travelModes";
import { useRepo } from "pinia-orm";
import SeeOnMapBtn from "@/components/buttons/SeeOnMapBtn.vue";
import TripadvisorImage from "@/models/TripadvisorImage";
import VoteBtn from "@/components/buttons/VoteBtn.vue";
import strfSeconds from "@/utils/strfSeconds";

export default {
  components: {
    SeeOnMapBtn,
    VoteBtn,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    placeId: {
      type: String,
      required: true,
    },
    showMapBtn: {
      type: Boolean,
      default: true,
    },
    tripId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      strfSeconds,
    };
  },
  computed: {
    authTripUser() {
      return this.$tripUserRepo
        .where("tripId", this.tripId)
        .where("userId", this.$userRepo.authUser.id)
        .with("places")
        .first();
    },
    cuisines() {
      return this.place.cuisines
        .slice(0, 3)
        .map((cuisine) => this.$t(`cuisines.${cuisine}`))
        .join(", ");
    },
    durationByPlaceId() {
      return this.authTripUser.places.reduce((accumulator, place) => {
        accumulator[place.id] = place.pivot.duration;
        return accumulator;
      }, {});
    },
    imageSrc() {
      return useRepo(TripadvisorImage)
        .where("placeId", this.placeId)
        .where("favorite", true)
        .first()?.url;
    },
    place() {
      return this.$placeRepo.find(this.placeId);
    },
    priceRange() {
      return this.$t(`priceRanges.${this.place.priceRange}`);
    },
    travelModes: getTravelModes,
  },
  methods: {
    onCardClick() {
      this.$router.push({
        name: "tripPlace",
        params: { tripId: this.tripId, placeId: this.placeId },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.actions
  bottom: 10px
  display: flex
  flex-wrap: wrap
  right: 10px
  position: absolute

  > *:not(:last-child)
    margin-right: 8px

.index
  align-items: center
  background-color: white
  border-radius: 50%
  color: var(--v-secondary-base)
  display: flex
  font-size: 18px
  font-weight: bold
  height: 30px
  justify-content: center
  left: 10px
  position: absolute
  top: 10px
  width: 30px
  border: 2px solid var(--v-secondary-base)
</style>
