<template>
  <v-input
    :error-messages="errorMessages"
    :label="$t('phoneNumberInput.label')"
  >
    <div style="width: 6em">
      <calling-code-select
        v-model="callingCode"
        :disabled="disabled"
        @input="onInput"
      />
    </div>
    <v-text-field
      v-model="phoneNumber"
      autocomplete="tel-national"
      :autofocus="autofocus"
      :disabled="disabled"
      hide-details
      @input="onInput"
    />
  </v-input>
</template>

<script>
import { VInput } from "vuetify/lib";
import { parsePhoneNumber } from "awesome-phonenumber";
import CallingCodeSelect from "@/components/inputs/CallingCodeSelect.vue";

export default {
  components: {
    CallingCodeSelect,
  },
  extends: VInput,
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      callingCode: "33",
      phoneNumber: "",
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (!value) return;
        const pn = parsePhoneNumber(value);
        if (!pn.valid) return;
        this.callingCode = pn.countryCode.toString();
        this.phoneNumber = pn.number.national;
      },
    },
  },
  methods: {
    onInput() {
      const pn = parsePhoneNumber(`+${this.callingCode}${this.phoneNumber}`);
      this.$emit(
        "input",
        pn.valid ? pn.number.e164 : `+${this.callingCode}${this.phoneNumber}`
      );
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-label
  font-size: 0.8rem
  position: absolute !important
  top: 0px
</style>
