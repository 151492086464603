<template>
  <div>
    <slot :events="{ showConfirmDialog: show }"></slot>
    <v-dialog v-model="dialog" width="640">
      <v-card class="rounded-lg">
        <v-form @submit.prevent="$emit('confirm')">
          <v-card-text>{{ message }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn rounded text @click="dialog = false">
              {{ $t("common.cancel") }}
            </v-btn>
            <v-btn color="primary" rounded type="submit">{{ action }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    show() {
      this.dialog = true;
    },
  },
};
</script>
