<template>
  <v-btn
    v-bind="$attrs"
    :disabled="trip.chosenPlaceId === place.id"
    @click="choosePlace"
  >
    <span>{{ $t("choosePlaceBtn.label") }}</span>
    <v-icon>mdi-check</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    place: {
      type: Object,
      required: true,
    },
    trip: {
      type: Object,
      required: true,
    },
  },
  methods: {
    choosePlace() {
      this.$tripRepo.choosePlace(this.trip.id, this.place.id);
    },
  },
};
</script>
