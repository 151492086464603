<template>
  <v-dialog fullscreen transition="dialog-bottom-transition" :value="true">
    <v-sheet class="d-flex fill-height flex-column">
      <v-toolbar class="flex-grow-0" flat>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <router-view />
      <v-container class="flex-grow-1 overflow-y-auto">
        <div class="mb-5 text-h5">{{ $t("tripUsers.title") }}</div>
        <v-list two-line>
          <v-list-item
            v-for="tripUser in tripUsers"
            :id="`user-${tripUser.userId}`"
            :key="tripUser.id"
          >
            <user-avatar
              :admin-badge="tripUser.isAdmin"
              :badge-icon="travelModes[tripUser.travelMode].icon"
              class="mr-3"
              :grayscale="!tripUser.joined"
              position="relative"
              size="48"
              :user="tripUser.user"
            />
            <v-list-item-content>
              <v-list-item-title>
                {{ tripUser.user.fullName }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="!tripUser.joined">
                {{ $t("tripUsers.notJoined") }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="tripUser.joined && !tripUser.address">
                {{ $t("tripUsers.noDepartureAddress") }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-card-actions>
              <v-btn
                v-if="tripUser.lat !== null && tripUser.lng !== null"
                color="primary"
                fab
                rounded
                :to="{
                  name: 'tripMapUser',
                  params: { tripId, userId: tripUser.userId },
                }"
                x-small
              >
                <v-icon>mdi-map</v-icon>
              </v-btn>
            </v-card-actions>
          </v-list-item>
        </v-list>
      </v-container>
      <v-footer v-if="authTripUser?.isAdmin" color="white">
        <v-container>
          <v-btn
            block
            class="my-3"
            color="primary"
            large
            rounded
            :to="{
              name: 'tripUserAdd',
              params: { tripId },
            }"
          >
            {{ $t("tripUsers.addParticipants") }}
          </v-btn>
        </v-container>
      </v-footer>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { getTravelModes } from "@/constants/travelModes";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  components: {
    UserAvatar,
  },
  computed: {
    authTripUser() {
      return this.$tripUserRepo
        .where("tripId", this.tripId)
        .where("userId", this.$userRepo.authUser.id)
        .first();
    },
    travelModes: getTravelModes,
    tripId() {
      return this.$route.params.tripId;
    },
    tripUsers() {
      return this.$tripUserRepo
        .where("tripId", this.tripId)
        .with("user")
        .orderBy((tripUser) => (tripUser.invited ? 1 : 0))
        .orderBy("invited")
        .get();
    },
    userId() {
      return this.$route.params.tripId;
    },
  },
  methods: {
    close() {
      this.$router.push({ name: "tripInfo", params: { tripId: this.tripId } });
    },
  },
};
</script>
