<template>
  <div>
    <v-app-bar app color="white" flat>
      <v-spacer />
      <v-btn exact icon :to="{ name: 'tripList' }">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <validation-observer
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-main>
        <v-container>
          <div class="mb-5 text-h5">{{ $t("tripCreate.title") }}</div>
          <div>{{ $t("tripForm.placeType.description") }}</div>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('tripForm.placeType')"
            rules="required"
            vid="placeType"
          >
            <place-type-toggle v-model="placeType" :errors="errors" />
          </validation-provider>
          <div v-show="placeType">
            <div class="mt-5">{{ $t("tripForm.datetime.description") }}</div>
            <div class="d-flex">
              <validation-provider
                v-slot="{ errors }"
                class="mr-3"
                :name="$t('tripForm.date')"
                rules="required"
                style="width: 100%"
                vid="date"
              >
                <date-input
                  v-model="date"
                  :errors="errors"
                  :label="$t('tripForm.date')"
                  :language="deviceStore.language"
                  :min="minDate"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('tripForm.time')"
                rules="required"
                style="width: 100%"
                vid="time"
              >
                <time-input
                  v-model="time"
                  :errors="errors"
                  :label="$t('tripForm.time')"
                  :min="minTime"
                />
              </validation-provider>
            </div>
          </div>
          <div v-show="placeType && date && time">
            <div class="mt-5">{{ $t("tripForm.info.description") }}</div>
            <v-text-field v-model.trim="name" :label="$t('tripForm.name')" />
            <v-textarea
              v-model.trim="description"
              :label="$t('tripForm.description')"
            />
          </div>
        </v-container>
      </v-main>
      <v-expand-transition>
        <v-footer v-if="!invalid" app color="white">
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              large
              rounded
              type="submit"
            >
              {{ $t("tripCreate.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import DateInput from "@/components/inputs/DateInput.vue";
import PlaceTypeToggle from "@/components/inputs/PlaceTypeToggle.vue";
import TimeInput from "@/components/inputs/TimeInput.vue";

export default {
  components: {
    DateInput,
    PlaceTypeToggle,
    TimeInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      placeType: null,
      date: "",
      time: "",
      name: "",
      description: "",
      tripId: null,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
    minDate() {
      const now = new Date();
      return this.toTimezoneOffsetISOString(now).split("T")[0];
    },
    minTime() {
      const now = new Date();
      if (this.date === this.minDate) {
        return this.toTimezoneOffsetISOString(now).split("T")[1].split(".")[0];
      }
      return null;
    },
  },
  methods: {
    async onSubmit() {
      const created = await this.createTrip();
      if (created) {
        this.$router.push({
          name: "tripUserAdd",
          params: { tripId: this.tripId },
        });
      }
    },
    async createTrip() {
      const arrivalDatetime = new Date(this.date + " " + this.time);
      const trip = await this.$tripRepo.create(
        this.placeType,
        arrivalDatetime,
        this.name,
        this.description
      );
      this.tripId = trip.id;
      return true;
    },
    toTimezoneOffsetISOString(dateString) {
      const date = new Date(dateString);
      return new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString();
    },
  },
};
</script>
