<template>
  <div>
    <v-app-bar app color="white" flat>
      <go-back-btn :query="{ redirect: $route.query.redirect }" />
    </v-app-bar>
    <validation-observer
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-main>
        <v-container>
          <div class="mb-5 text-h5">
            {{ $t("userResetPasswordStep1.title") }}
          </div>
          <p>{{ $t("userResetPasswordStep1.description") }}</p>
          <validation-provider
            :name="$t('userResetPasswordStep1.phoneNumber')"
            rules="required|phoneNumber"
            vid="phoneNumber"
          >
            <phone-number-input v-model="phoneNumber" />
          </validation-provider>
        </v-container>
      </v-main>
      <v-expand-transition>
        <v-footer v-if="!invalid" app color="white">
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              large
              rounded
              type="submit"
            >
              {{ $t("userResetPasswordStep1.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import PhoneNumberInput from "@/components/inputs/PhoneNumberInput.vue";
import UserService from "@/services/middler/UserService";

const userService = new UserService();

export default {
  components: {
    GoBackBtn,
    PhoneNumberInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      phoneNumber: this.$route.query.phoneNumber,
    };
  },
  methods: {
    async onSubmit() {
      const isUserExists = await userService.isExists(this.phoneNumber);
      if (isUserExists) {
        this.$router.push({
          name: "userResetPasswordStep2",
          query: {
            phoneNumber: this.phoneNumber,
            redirect: this.$route.query.redirect,
          },
        });
      } else {
        this.$router.push({
          name: "userSignUpStep1",
          query: {
            phoneNumber: this.phoneNumber,
            redirect: this.$route.query.redirect,
          },
        });
      }
    },
  },
};
</script>
