<template>
  <div class="d-flex">
    <v-icon class="mr-2" small>mdi-bell-off-outline</v-icon>
    <v-switch
      :disabled="!notificationStore.isSupported"
      :input-value="notificationStore.isEnabled"
      :loading="notificationStore.loading"
      @change="toggle"
    />
    <v-icon class="ml-2" small>mdi-bell-outline</v-icon>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useNotificationStore } from "@/stores/notification";
import NotificationHandler from "@/utils/NotificationHandler";

export default {
  data() {
    return {
      notificationHandler: new NotificationHandler(),
    };
  },
  computed: {
    ...mapStores(useNotificationStore),
  },
  methods: {
    async toggle() {
      await this.notificationHandler.toggle();
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-input__append-outer
  margin-left: 30px
</style>
