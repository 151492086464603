<template>
  <v-icon v-if="type == 'restaurants'" v-bind="$attrs" v-on="$listeners">
    mdi-silverware-fork-knife
  </v-icon>
  <v-icon v-else-if="type == 'bars_and_pubs'" v-bind="$attrs" v-on="$listeners">
    mdi-glass-cocktail
  </v-icon>
  <v-icon
    v-else-if="type == 'coffee_and_tea'"
    v-bind="$attrs"
    v-on="$listeners"
  >
    mdi-coffee
  </v-icon>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
