<template>
  <v-dialog fullscreen transition="dialog-bottom-transition" :value="true">
    <v-sheet class="d-flex fill-height flex-column">
      <v-toolbar class="flex-grow-0" flat>
        <v-btn v-if="showBackBtn" icon @click="back">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="d-flex flex-column flex-grow-1 overflow-y-auto">
        <div class="mb-5 text-h5">{{ $t("tripUserAdd.title") }}</div>
        <div class="text-h7">
          {{ $t("tripUserAdd.giveAccessToEvent") }}
        </div>
        <v-list>
          <v-list-item v-if="canCopy" @click="copy">
            <v-list-item-title>
              {{ $t("tripUserAdd.copyLink") }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-content-copy</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item v-else class="overflow-auto">
            {{ shareUrl }}
          </v-list-item>
          <v-list-item v-if="canShare" @click="share">
            <v-list-item-title>
              {{ $t("tripUserAdd.shareLink") }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-share-variant-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-divider
          v-if="(canCopy || canShare) && users.length > 0"
          class="mb-5"
        />
        <div v-if="users.length > 0" class="text-h7">
          {{ $t("tripUserAdd.inviteContacts") }}
        </div>
        <v-container v-if="loading" class="d-flex justify-center" fill-height>
          <v-progress-circular
            color="primary"
            indeterminate
            :size="70"
          ></v-progress-circular>
        </v-container>
        <v-list v-else class="flex-grow-1 overflow-y-auto">
          <v-list-item v-for="user in users" :key="user.id">
            <user-avatar class="mr-2" :user="user" />
            <v-list-item-title>
              {{ user.fullName }}
            </v-list-item-title>
            <v-btn
              :disabled="!isTripUser(user)"
              rounded
              small
              @click="invite(user)"
            >
              {{ $t("tripUserAdd.invite") }}
            </v-btn>
          </v-list-item>
        </v-list>
        <v-snackbar v-model="snackbar" :timeout="1000">
          {{ $t("tripUserAdd.copiedToClipboard") }}
        </v-snackbar>
      </v-container>
      <v-footer v-if="showContinueBtn" color="white">
        <v-container>
          <v-btn
            block
            class="my-3"
            color="primary"
            large
            rounded
            :to="{
              name: 'tripUserEdit',
              params: { tripId, userId: $userRepo.authUser.id },
            }"
          >
            {{ $t("tripUserAdd.continue") }}
          </v-btn>
        </v-container>
      </v-footer>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import { mapStores } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  components: {
    UserAvatar,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
      vm.showBackBtn = vm.prevRoute.name === "tripUsers";
      vm.showContinueBtn = vm.prevRoute.name === "tripCreate";
    });
  },
  data() {
    const tripId = this.$route.params.tripId;
    const canCopy = Capacitor.isNativePlatform() || navigator.clipboard;
    const canShare = Capacitor.isNativePlatform() || navigator.share;
    return {
      tripId,
      canCopy,
      canShare,
      snackbar: false,
      showBackBtn: false,
      showContinueBtn: false,
    };
  },
  computed: {
    ...mapStores(useLoadingStore),
    loading() {
      return this.loadingStore["user.listRelatedAndContactUsers"];
    },
    trip() {
      return this.$tripRepo.find(this.tripId);
    },
    users() {
      return this.$userRepo
        .where("isAuthenticated", false)
        .orderBy("fullName")
        .get();
    },
    shareUrl() {
      const protocol = process.env.VUE_APP_URL_PROTOCOL;
      const host = process.env.VUE_APP_URL_HOST;
      return this.trip && this.trip.shareKey
        ? `${protocol}://${host}/${this.tripId}?shareKey=${this.trip.shareKey}`
        : null;
    },
  },
  created() {
    this.$userRepo.listRelatedAndContactUsers();
  },
  methods: {
    async copy() {
      if (Capacitor.isNativePlatform()) {
        await Clipboard.write({
          string: this.shareUrl,
        });
      } else if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.shareUrl);
        this.snackbar = true;
      }
    },
    async share() {
      if (Capacitor.isNativePlatform()) {
        await Share.share({
          text: this.$t("tripUserAdd.shareText"),
          url: this.shareUrl,
        });
      } else if (navigator.share) {
        await navigator.share({
          text: this.$t("tripUserAdd.shareText"),
          url: this.shareUrl,
        });
      }
    },
    isTripUser(user) {
      return !this.$tripUserRepo
        .where("tripId", this.tripId)
        .where("userId", user.id)
        .first();
    },
    back() {
      this.$router.push({ name: "tripUsers", params: { tripId: this.tripId } });
    },
    close() {
      this.$router.push({ name: "tripInfo", params: { tripId: this.tripId } });
    },
    async invite(user) {
      await this.$tripRepo.invite(this.tripId, user.id);
    },
  },
};
</script>
