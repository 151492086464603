import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      // light: {
      //   primary: "#1976d2",
      //   secondary: "#424242",
      //   accent: "#82b1ff",
      //   error: "#ff5252",
      //   info: "#2196f3",
      //   success: "#4caf50",
      //   warning: "#fb8c00",
      // },
      light: {
        primary: "#00b0ff",
        secondary: "#e3a55b",
        accent: "#82b1ff",
        error: "#e35b6d",
        info: "#2196f3",
        success: "#4caf50",
        warning: "#fb8c00",
      },
      dark: {
        primary: "#2196f3",
        secondary: "#424242",
        accent: "#ff4081",
        error: "#ff5252",
        info: "#2196f3",
        success: "#4caf50",
        warning: "#fb8c00",
      },
    },
  },
});
