<template>
  <confirm-dialog
    :action="$t('common.yes')"
    :message="$t('voteBtn.confirmDialog.putToVote')"
    @confirm="createVote()"
  >
    <template #default="{ events: { showConfirmDialog } }">
      <v-btn
        :color="color"
        :fab="fab"
        :outlined="outlined"
        rounded
        :small="!fab"
        :x-small="fab"
        @click.capture.stop="isPollExist ? goToVote() : showConfirmDialog()"
      >
        <span v-if="!fab">{{ $t("voteBtn.label") }}</span>
        <v-icon>mdi-thumb-up</v-icon>
      </v-btn>
    </template>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    color: {
      type: String,
      default: null,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    placeId: {
      type: String,
      required: true,
    },
    tripId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isPollExist() {
      return this.$pollRepo
        .where("tripId", this.tripId)
        .where("placeId", this.placeId)
        .first();
    },
  },
  methods: {
    async createVote() {
      const poll = await this.$pollRepo.create(this.tripId, this.placeId);
      await this.$pollRepo.vote(this.tripId, poll.id, true);
      this.goToVote();
    },
    goToVote() {
      this.$router.push({
        name: "tripMessages",
        params: { tripId: this.tripId },
      });
    },
  },
};
</script>
