<template>
  <div>
    <v-app-bar app color="primary" :extension-height="120" flat>
      <go-back-btn dark exact />
      <template #extension>
        <v-spacer />
        <v-badge bottom class="mb-8" color="white" icon="mdi-pencil">
          <v-btn fab :to="{ name: 'userSettingsAvatar' }">
            <user-avatar size="96" :user="$userRepo.authUser" />
          </v-btn>
        </v-badge>
        <v-spacer />
      </template>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-list>
          <v-list-item>
            <v-list-item-title class="font-weight-bold">
              {{ $t("userSettings.myAccount") }}
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item :to="{ name: 'userSettingsName' }">
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $userRepo.authUser.fullName }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-cellphone</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ nationalPhoneNumber }}
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item :to="{ name: 'userSettingsPassword' }">
            <v-list-item-icon>
              <v-icon>mdi-lock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("userSettings.password") }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("userSettings.logout") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="font-weight-bold">
              {{ $t("userSettings.preferences") }}
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item :to="{ name: 'userSettingsDefaultTravelMode' }">
            <v-list-item-icon>
              <v-icon>
                {{ travelModes[$userRepo.authUser.defaultTravelMode].icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("userSettings.defaultTravelMode") }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-title class="font-weight-bold">
              {{ $t("userSettings.settings") }}
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item :to="{ name: 'userSettingsLanguage' }">
            <v-list-item-icon>
              <v-icon>
                {{ languages[deviceStore.language].flag }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("userSettings.language") }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item :disabled="!isFirebaseMessagingSupported">
            <v-list-item-icon>
              <v-icon>mdi-bell-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("userSettings.notifications") }}
            </v-list-item-title>
            <notification-switch />
          </v-list-item>
          <v-list-item class="gray--text">
            {{ $t("userSettings.version", { value: appVersion }) }}
          </v-list-item>
        </v-list>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { getLanguages } from "@/constants/languages";
import { getTravelModes } from "@/constants/travelModes";
import { mapStores } from "pinia";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useDeviceStore } from "@/stores/device";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import NotificationSwitch from "@/components/NotificationSwitch.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  components: {
    GoBackBtn,
    NotificationSwitch,
    UserAvatar,
  },
  data: () => {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      isFirebaseMessagingSupported: false,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
    languages: getLanguages,
    nationalPhoneNumber() {
      const pn = parsePhoneNumber(this.$userRepo.authUser.phoneNumber);
      return pn.number.national;
    },
    travelModes: getTravelModes,
  },
  async created() {
    const { isSupported } = await FirebaseMessaging.isSupported();
    this.isFirebaseMessagingSupported = isSupported;
  },
  methods: {
    logout() {
      this.$userRepo.logout();
      this.$router.push({ name: "userAuth" });
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-toolbar__extension
  background-color: var(--v-primary-base)

::v-deep .v-badge__badge .v-icon
  color: var(--v-primary-base)
::v-deep .v-input__slot
  padding: 0px !important
</style>
