<template>
  <div>
    <v-app-bar app color="primary" dark flat>
      <go-back-btn exact />
      <v-spacer />
      <v-toolbar-title class="mr-8">
        {{ $t("userSettingsAvatar.title") }}
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <validation-observer
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-main>
        <v-container>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userSettingsAvatar.avatar')"
            vid="avatar"
          >
            <image-input
              :error-messages="errors"
              :url="user.avatar"
              @change="avatarFile = $event"
            />
          </validation-provider>
        </v-container>
      </v-main>
      <v-footer app color="white">
        <v-container>
          <v-btn
            block
            class="my-3"
            color="primary"
            :disabled="invalid"
            large
            rounded
            type="submit"
          >
            {{ $t("userSettingsAvatar.action") }}
          </v-btn>
        </v-container>
      </v-footer>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { useRepo } from "pinia-orm";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import ImageInput from "@/components/inputs/ImageInput.vue";
import UserRepository from "@/repositories/UserRepository";
import _clone from "lodash/clone";

export default {
  components: {
    GoBackBtn,
    ImageInput,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => {
    const userRepo = useRepo(UserRepository);
    const user = _clone(userRepo.authUser);
    return {
      avatarFile: undefined,
      user,
    };
  },
  methods: {
    async onSubmit() {
      await this.$userRepo.update(this.user, this.avatarFile);
      this.$router.push({ name: "userSettings" });
    },
  },
};
</script>
